<template>
	<form class="main-wrapper mt-4 mb-3" @submit.prevent="handleSubmit">
		<BannerGeneral
			:id="id"
			ref="general"
			name-label="Small banner name*"
			:name="name"
			:priority="priority"
			:status="status"
			:widgets="widgets"
			:widget-type="widgetType"
			show-widget
			@update:name="name = $event"
			@update:priority="priority = $event"
			@update:status="status = $event"
			@update:widgets="widgets = $event"
		/>

		<BannerDate
			ref="date"
			:status="status"
			:start-date="startDate"
			:start-time="startTime"
			:end-date="endDate"
			:end-time="endTime"
			@update:startDate="startDate = $event"
			@update:startTime="startTime = $event"
			@update:endDate="endDate = $event"
			@update:endTime="endTime = $event"
		/>

		<BannerContentWrapper
			ref="content"
			desktop-img="/assets/images/small-banner-desktop.svg"
			mobile-img="/assets/images/small-banner-mobile.svg"
			:required-link="!!desktopBanner && !!mobileBanner"
			:banner-link="bannerLink"
			has-title
			required-title
			:title-th="titleTh"
			:title-en="titleEn"
			has-sub-title
			is-required-sub-title
			:sub-title-th="subTitleTh"
			:sub-title-en="subTitleEn"
			has-description
			is-required-description
			:description-th="descriptionTh"
			:description-en="descriptionEn"
			has-badge-label
			:badge-labels="badgeLabels"
			@update:badgeLabels="badgeLabels = $event"
			@update:bannerLink="bannerLink = $event"
			@update:titleTh="titleTh = $event"
			@update:titleEn="titleEn = $event"
			@update:subTitleTh="subTitleTh = $event"
			@update:subTitleEn="subTitleEn = $event"
			@update:descriptionTh="descriptionTh = $event"
			@update:descriptionEn="descriptionEn = $event"
		>
			<template #title>
				Small banner
			</template>
			<BaseMediaSelectorBox
				ref="desktopBanner"
				v-model="desktopBanner"
				title="Desktop banner"
				no-image-text="No banner"
				show-helper
				width="800"
				height="357"
				class="mt-4"
				required
			/>
			<BaseMediaSelectorBox
				ref="mobileBanner"
				v-model="mobileBanner"
				title="Mobile banner"
				no-image-text="No banner"
				show-helper
				width="760"
				height="576"
				class="mt-2"
				required
			/>
		</BannerContentWrapper>

		<BaseActionPanelStickyFooter
			:disabled-confirm="isLoading || isUpdating"
			:is-edit="isEditMode"
			:remove-text="isEditMode ? 'Remove banner' : null"
			@onConfirm="handleSubmit"
			@onCancel="$router.push({ name: 'BannerSmallList'})"
			@onRemove="isEditMode ? $refs['modal-remove'].open() : null"
		/>

		<BaseModalConfirmDelete
			ref="modal-remove"
			:handle-remove="deleteBanner.bind(null, id)"
			title="Remove this banner?"
			description="By removing this, all banner info will be disappeared from the list and linked widgets."
			@onSuccess="$router.push({ name: 'BannerSmallList'})"
		/>
	</form>
</template>

<script>
import { mapActions } from 'vuex';

import BannerGeneral from '@/components/BannerGeneral.vue';
import BannerDate from '@/components/BannerDate.vue';
import BannerContentWrapper from '@/components/BannerContentWrapper.vue';
import BaseMediaSelectorBox from '@/components/BaseMediaSelectorBox.vue';

import { BANNER_TYPE, BANNER_IMAGE_TYPE } from '../enums/banners';
import { convertDateTimeToUTC } from '../assets/js/helpers';

export default {
	name: 'BannerSmallForm',

	components: {
		BannerGeneral,
		BannerDate,
		BannerContentWrapper,
		BaseMediaSelectorBox,
	},

	props: {
		isEditMode: {
			type: Boolean,
			default: false,
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
		isUpdating: {
			type: Boolean,
			default: false,
		},
		data: {
			type: Object,
			default: null,
		},
		bannerType: {
			type: String,
			default: BANNER_TYPE.small,
		},
	},

	data() {
		return {
			id: null,
			name: '',
			priority: 0,
			widgets: [],
			status: true,
			startDate: null,
			startTime: '00:00',
			endDate: null,
			endTime: '23:59',
			desktopBanner: null,
			mobileBanner: null,
			bannerLink: '',
			badgeLabels: [],
			titleTh: '',
			titleEn: '',
			subTitleTh: '',
			subTitleEn: '',
			descriptionTh: '',
			descriptionEn: '',
		};
	},

	computed: {
		widgetType() {
			const widget = {
				hero: 'hero_banner',
				highlight: 'highlight_banner',
				small: 'small_banner',
			};

			return widget[this.bannerType];
		},
	},

	watch: {
		data: {
			immediate: true,
			handler(value) {
				if (this.isEditMode && !this.isUpdating) {
					this.id = value.id;
					this.name = value.name;
					this.priority = value.priority;
					this.status = value.status;
					this.startDate = value.startDate;
					this.startTime = value.startTime;
					this.endDate = value.endDate;
					this.endTime = value.endTime;
					this.desktopBanner = value.banners.desktopBanner;
					this.mobileBanner = value.banners.mobileBanner;
					this.bannerLink = value.banners.bannerLink;
					this.badgeLabels = value.labels;
					this.widgets = value.widgets;

					this.titleTh = value.banners.desktopBanner.titleTh;
					this.titleEn = value.banners.desktopBanner.titleEn;

					this.subTitleTh = value.banners.desktopBanner.subTitleTh;
					this.subTitleEn = value.banners.desktopBanner.subTitleEn;

					this.descriptionTh = value.banners.desktopBanner.descriptionTh;
					this.descriptionEn = value.banners.desktopBanner.descriptionEn;
				}
			},
		},
	},

	methods: {
		...mapActions({
			deleteBanner: 'banners/deleteBanner',
		}),

		handleChangeEndDate(value) {
			this.endDate = value;
		},

		addBanner(file, type, link, { titleTh, titleEn, subTitleTh, subTitleEn, descriptionTh, descriptionEn }) {
			const result = {};
			result.file = file ? file.id : null;
			result.type = type;

			if (link) {
				result.link = link;
			}

			result.title_th = titleTh;
			result.title_en = titleEn;
			result.sub_title_th = subTitleTh;
			result.sub_title_en = subTitleEn;
			result.description_th = descriptionTh;
			result.description_en = descriptionEn;

			return result;
		},

		async handleSubmit() {
			const generalRef = this.$refs.general.$v;
			const dateRef = this.$refs.date.$v;

			const contentRef = this.$refs.content.$v;
			const desktopBannerRef = this.$refs.desktopBanner.$v;
			const mobileBannerRef = this.$refs.mobileBanner.$v;

			generalRef.$touch();
			dateRef.$touch();
			contentRef.$touch();
			desktopBannerRef.$touch();
			mobileBannerRef.$touch();

			if (
				!generalRef.$invalid &&
				!dateRef.$invalid &&
				!contentRef.$invalid &&
				!desktopBannerRef.$invalid &&
				!mobileBannerRef.$invalid
			) {
				const bannerContent = {
					titleTh: this.titleTh,
					titleEn: this.titleEn,
					subTitleTh: this.subTitleTh,
					subTitleEn: this.subTitleEn,
					descriptionTh: this.descriptionTh,
					descriptionEn: this.descriptionEn,
				};

				const data = {
					name: this.name,
					type: BANNER_TYPE.small,
					priority: Number(this.priority, 10),
					is_visible: this.status,
					start_at: convertDateTimeToUTC(this.startDate, this.startTime),
					end_at: convertDateTimeToUTC(this.endDate, this.endTime),
					label_ids: this.badgeLabels.map((item) => item.id) || [],
					banners: [
						this.addBanner(
							this.desktopBanner,
							BANNER_IMAGE_TYPE.homepage_small_banner_image,
							this.bannerLink,
							bannerContent,
						),
						this.addBanner(
							this.mobileBanner,
							BANNER_IMAGE_TYPE.homepage_mobile_small_banner_image,
							this.bannerLink,
							bannerContent,
						),
					],

					widget_ids: this.widgets.map((widget) => widget.id),
				};

				this.$emit('onSubmit', data);
			}
		},

		handleRemove() {
			this.$emit('onRemove');
		},
	},
};
</script>

<style lang="scss" scoped>
	.remove-banners {
		font-size: rem(14);
		color: $color-orange;

		align-items: center;

		cursor: pointer;
	}
</style>
